<template>
  <div class="nav-wrapper">
    <div id="nav" :class="{fixed:isFixed, visible:isVisible} "
         style="opacity: 1; animation: 1s ease 0s 1 normal none running headerNoOpacity;">
      <router-link v-if="siteInfo" id="site-name" to="/">{{ siteInfo.baseInfo.blogName }} ✨</router-link>
      <div class="nav-menus-wrapper">
      <span class="menus">
        <div class="menus_items">
            <div class="menus_item">
              <router-link class="site-page" to="/home">
                    <i class="fa-fw fas fa-home"></i>
                    <span>
                        首页
                    </span>
              </router-link>
            </div>
            <div class="menus_item">
              <router-link class="site-page" to="/timeline">
                  <i class="fa-sharp fa-solid fa-list-timeline"></i>
                  <span> 时间轴</span>
              </router-link>
            </div>
            <div class="menus_item">
              <a class="site-page">
                    <i class="fa-fw fas fa-folder-open"></i>
                    <span> 分类</span>
                <i class="fas fa-chevron-down menus-expand"></i>
              </a>
              <ul class="menus_item_child">
                <li v-for="(item, index) in categoryList">
                  <!--最多展示10个-->
                  <router-link v-if="index < 10" :to="`/category/${item.categoryName}`" class="site-page">
                    <i :class="item.icon" :style="{color: item.color}"></i>
                    <span> {{ item.categoryName }} </span>
                  </router-link>
                </li>
              </ul>
            </div>
          <!--<div class="menus_item">-->
          <!--    <router-link class="site-page" to="/moments">-->
          <!--        <i class="fa-solid fa-comment-dots"></i>-->
          <!--        <span> 动态</span>-->
          <!--    </router-link>-->
          <!--</div>-->
            <div class="menus_item">
                <router-link class="site-page" to="/friends">
                    <i class="fa-fw fas fa-link"></i>
                    <span> 邻居们</span>
                </router-link>
            </div>
          <div class="menus_item">
                <router-link class="site-page" to="/about">
                    <i class="fa-solid fa-fingerprint"></i>
                    <span> 关于我</span>
                </router-link>
            </div>
            <div class="menus_item">

              <!--<a class="site-page">-->
              <!--    <span class="footer__heart">❤️更多</span>-->
              <!--    <i class="fas fa-chevron-down menus-expand"></i>-->
              <!--</a>-->
              <!--<ul class="menus_item_child">-->
              <!--    <li>-->
              <!--        <a class="site-page" href="/github" target="_self">-->
              <!--            <i class="fa-fw fas">-->
              <!--                <img class="page-icon" src="">-->
              <!--            </i>-->
              <!--            <span> 我的开源</span>-->
              <!--        </a>-->
              <!--    </li>-->
              <!--</ul>-->
            </div>
        </div>
        <span class="toggle-menu close">
            <a class="site-page">
                <i class="fas fa-bars fa-fw"></i>
            </a>
        </span>
    </span>
        <!--黑暗模式按钮-->
        <span class="dark-button">
       <div id="dayNightSwitch" class="generalWrapper">
                <div class="onOff daySwitch" @click="daySwitch">
                    <div class="star star1"></div>
                    <div class="star star2"></div>
                    <div class="star star3"></div>
                    <div class="star star4"></div>
                    <div class="star star5"></div>
                    <div class="star sky"></div>
                    <div class="sunMoon">
                        <div class="crater crater1"></div>
                        <div class="crater crater2"></div>
                        <div class="crater crater3"></div>
                        <div class="cloud part1"></div>
                        <div class="cloud part2"></div>
                    </div>
                </div>
              </div>
    </span>
      </div>
    </div>
  </div>
</template>
<script>
/*导航栏样式*/
import "@/assets/css/nav.css";
import {mapState} from "vuex";

export default {
  name: 'Nav',
  props: {
    msg: String
  },
  computed: {
    ...mapState(['siteInfo'])
  },
  data() {
    return {
      isFixed: true,
      isVisible: false,
      topDif: null,
      daySwitchOnload: null,
      categoryList: {},
    }
  },
  created() {
    //初始化黑暗模式按钮初始状态，经测试循环3次即可!=null，不会产生性能损耗
    let choseDay = setInterval(() => {
      let onOff = document.querySelector(".onOff")
      if (onOff != null) {
        this.initDayMode()
        clearInterval(choseDay)
      }
    }, 1)
    //初始化导航栏的分类
    this.getCategoryList()
  },
  mounted() {
    document.onscroll = () => {
      this.topDif = document.documentElement.scrollTop
    }
  },
  watch: {
    topDif: {
      handler(newVal, oldVal) {
        //下滑且滑出导航栏范围时：隐藏
        if (newVal > oldVal && newVal > 42 && oldVal != null) {
          this.isVisible = false
          this.isFixed = true
          this.$store.commit('setNavVisible', false)
        } else if (newVal < oldVal && newVal != 0) {
          //上滑且未到顶部时：透明板辅助显示
          this.isVisible = true
          this.isFixed = true
          this.$store.commit('setNavVisible', true)
        } else if (oldVal == null && document.documentElement.scrollTop < 42) {
          //刷新且页面位置在导航栏范围内时：正常显示
          this.isFixed = false
          this.isVisible = false
          this.$store.commit('setNavVisible', false)
        } else if (newVal == 0 && oldVal != null) {
          //上滑且回到顶部时：正常显示
          this.isVisible = false
          this.isFixed = false
          this.$store.commit('setNavVisible', false)
        }
      },
      immediate: true
    },
  },
  methods: {
    getCategoryList() {
      this.$request.get("/category").then(res => {
        this.categoryList = res.data
      })
    },
    //切换黑暗模式
    daySwitch() {
      let bodeEl = document.querySelector("body")
      let daySwitch = document.querySelector(".daySwitch")
      let onOff = document.querySelector(".onOff")
      let list = onOff.children
      for (let i = 0; i < list.length; i++) {
        list[i].style.transition = "all .4s ease"
      }
      if (daySwitch != null) {
        localStorage.setItem("dayMode", "dark")
        bodeEl.classList.add("dark-mode")
        onOff.classList.remove("daySwitch")
        onOff.style.background = "#324164"
      } else {
        localStorage.setItem("dayMode", "light")
        bodeEl.classList.remove("dark-mode")
        onOff.classList.add("daySwitch")
        onOff.style.background = "#5ebedb"
      }
    },
    initDayMode() {
      let dayMode = localStorage.getItem("dayMode")
      let onOff = document.querySelector(".onOff")
      if (dayMode === "dark") {
        onOff.style.background = "#324164"
        onOff.classList.remove("daySwitch")
        let bodeEl = document.querySelector("body")
        bodeEl.classList.add("dark-mode")
      } else {
        onOff.style.background = "#5ebedb"
      }
    }
  }

}
</script>

<style scoped>


body {
  position: relative;
  min-height: 100%;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Lato, Roboto, 'PingFang SC', 'Microsoft YaHei', sans-serif;
}

a:-webkit-any-link {
  /*去除用户代理表的默认设置*/
  text-decoration: none;
}

.nav-menus-wrapper {
  display: flex;
}

.nav-wrapper {
  position: relative;
}
</style>