<template>
	<meting-js server="netease" type="playlist" id="7524769794" fixed="true" theme="#b7daff"></meting-js>
</template>

<script>
	export default {
		name: "MyAPlayer"
	}
</script>

<style scoped>

</style>