<template>
  <el-card shadow="hover" style="width: 300px">
    <div class="rec-banner">
      <i class="fa-brands fa-pagelines"></i>
      随便看看
    </div>
    <div v-for="item in recList" class="rec-list">
      <router-link :to="`/blog/${item.id}`">
        <img :src="item.firstPicture">
      </router-link>
      <div class="rec-info">
        <router-link :to="`/blog/${item.id}`">
          <span class="rec-title">{{ item.title }}</span>
        </router-link>
        <span class="time">{{ item.createTime | dateFormat() }}</span>

      </div>
    </div>

    <div class="rec-article"></div>
  </el-card>
</template>

<script>
export default {
  name: "TagCloud",
  data() {
    return {
      recList: null
    }
  },
  created() {
    this.getTagCloud()
  },
  methods: {
    getTagCloud() {
      this.$request.get("/blog/rec-summary").then(res => {
        this.recList = res.data
      })
    }
  }
}
</script>

<style scoped>
/deep/ .el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
  box-shadow: 0 4px 12px 12px rgb(7 17 27 / 15%);
}

.rec-text a:hover {
  transform: scale(1.2);
}

.rec-banner {
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #ebeef5;
  font-size: 20px;
}

.fa-pagelines {
  color: #02C39A;
}

.rec-list {
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid #ebeef5;
  overflow: hidden;
}

.rec-list a {
  overflow: hidden;
  border-radius: 8px;
  width: 4.2em;
  height: 4.2em;
}

.rec-list img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.6s;
}

.rec-list img:hover {
  transform: scale(1.1);
}

.rec-info {
  flex: 1;
  padding-left: 10px;
}

.rec-info .rec-title {
  font-size: 16px;
  line-height: 1.5;
  color: #4c4948;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.2s;
}

.rec-info .rec-title:hover {
  color: #49b1f5;
}

.rec-info .time {
  font-size: 15px;
  font-family: SAOUI, serif;
  font-weight: 700;
  color: #858585;
}

</style>