<template>
  <div>
    <!--首页-->
    <header v-show="$route.name === 'home'" class="header" style="height: 100vh;position: relative;">
      <!--页头首图-->
      <div id="home-header">
        <img :src="homePicList[homePicIndex]">
      </div>
      <!--中间抖音风博客名-->
      <div v-if="siteInfo" :data-word="siteInfo.baseInfo.blogName" class="text-malfunction name">
        <div class="line"></div>
      </div>
      <!--下方的下滑箭头-->
      <div class="headertop-down" @click="scrollDown">
        <i aria-hidden="true" class="fa fa-chevron-down faa-bounce faa-reverse animated"></i>
      </div>
    </header>
    <!--其他页-->
    <header v-if="$route.name !== 'home'" :key="$route.fullPath">
      <div class="page-header" ref="pageHeader">
        <img :src="pagePicList[$route.name]">
        <!--标签页-->
        <p class="headTitle" v-if="$route.name === 'tag'">
          {{ "标签 " }}<i>{{ tagName }}</i>{{ " 下的文章 • 共" }}{{ headerInfo.total }}{{ "篇" }}
        </p>
        <!--分类页-->
        <p class="headTitle" v-if="$route.name === 'category' ">
          {{ "分类 " }}<i>{{ categoryName }}</i>{{ " 下的文章 • 共" }}{{ headerInfo.total }}{{ "篇" }}
        </p>
        <!--时间轴页-->
        <p class="headTitle" v-if="$route.name === 'timeline'">
          {{ "时间轴 • 文章归档" }}
        </p>
        <!--动态页-->
        <p class="headTitle" v-if="$route.name === 'moments'">
          {{ "动态 • 共低语了" + headerInfo.total + "次碎碎念" }}
        </p>
        <!--邻居们页-->
        <p class="headTitle" v-if="$route.name === 'friends'">
          {{ "邻居们 • 共结识了" + headerInfo.total +"位浮莲子" }}
        </p>
        <p class="headTitle" v-if="$route.name === 'about'">
          {{ "关于Iceburst • 和他的小破站" }}
        </p>
        <div v-if="$route.name === 'blog'" style="position: absolute">
          <!--博客页-->
          <p class="headTitle" style="position: unset">{{ headerInfo.title }}</p>
          <!--博客文章信息-->
          <div class="head-category-wrap" v-if="$route.name === 'blog'" >
                <span class="head-category">
                  <i class="fas fa-calendar-alt"></i>
                  <a>{{ headerInfo.createTime | dateFormat() }}</a>
                  <span></span>
                  <i class="fas fa-eye"></i>
                  <a>{{ headerInfo.views }}</a>
                  <span></span>
                  <i class="fa-solid fa-pen-to-square"></i>
                  <a>字数≈{{ headerInfo.words }}字</a>
                  <span></span>
                  <i class="fas fa-clock"></i>
                  <a>阅读时长≈{{ headerInfo.readTime }}分</a>
                </span>
          </div>
        </div>

      </div>
    </header>
  </div>
</template>

<script>
//页头样式
import "@/assets/css/header.css"
import {mapState} from "vuex";
import {clickMagic} from "@/assets/js/circleMagic";

export default {
  name: "Header",
  props: {
    msg: String
  },
  created() {
  },
  data() {
    return {
      tagName: this.$route.params.tagName,
      categoryName: this.$route.params.categoryName,
      homePicList: ["https://i0.hdslb.com/bfs/album/be476b6009515da08324ee1f234839b85d3fc557.jpg@1e_1c.webp",
        "https://i0.hdslb.com/bfs/album/91ebd1da3b541127d197efd78ce3ad48ce46b475.jpg@1e_1c.webp",
        "https://i0.hdslb.com/bfs/album/7b04e27c8fd9edb03e764aee2b6f378f15867de8.jpg@1e_1c.webp",
        "https://i0.hdslb.com/bfs/album/70b2c0f516fb2649b78622c42b7d039e121b0bdc.jpg@1e_1c.webp",
        "https://i0.hdslb.com/bfs/album/b8dd39ed57a14f76df55d690aca8410ed870e695.jpg@1e_1c.webp"],
      homePicIndex: 0,
      homePicSrc: null,
      pagePicList: {
        timeline: "https://i0.hdslb.com/bfs/album/5ba55e3bbb2219d7781ebc4ea2296461a77b171b.webp",
        category: "https://i0.hdslb.com/bfs/album/7f92cae88c3866d7bce6bb064d9628a14700485c.jpg@1e_1c.webp",
        tag: "https://i0.hdslb.com/bfs/album/7f92cae88c3866d7bce6bb064d9628a14700485c.jpg@1e_1c.webp",
        moments: "http://i0.hdslb.com/bfs/album/520d060aa63347ff10540534955a3f2bced7e2ee.png",
        friends: "https://i0.hdslb.com/bfs/album/616b22e4db332dbe9d860f8ed2255b48e3eb4dad.png@1e_1c.webp",
        about: "https://i0.hdslb.com/bfs/album/9043b4fd32ae2718e02cc546124950cd5e0b268d.png@1e_1c.webp",
        blog: "https://i0.hdslb.com/bfs/album/795c86c2b8e0321cccd4d1aa076100ef6a70a015.png"
      },
    }
  },
  mounted() {
      clickMagic()
  },
  computed: {
    ...mapState(['siteInfo', 'headerInfo'])
  },
  //原设计是{{ "标签 " + this.$route.params.tagName + " 下的文章 • 共" + headerInfo.total + "篇" }}
  //但会导致headerInfo.total的更新速度慢于this.$route.params.tagName，造成延迟
  //在data里存储this.$route.params.tagName并通过watch监听实现同步更新可消除延迟，原理不明
  //猜测是取值来源不同，获取耗时也不同，极短时间内重复渲染导致的延迟
  watch: {
    '$route.fullPath'() {
      this.tagName = this.$route.params.tagName
      this.categoryName = this.$route.params.categoryName
    },
    '$route.name'(){
      this.setHomePicSrc()
    }
  },
  methods: {
    scrollDown() {
      let photoH = document.querySelector('.header').offsetHeight
      window.scrollTo({top: photoH, behavior: "smooth"})
    },
    setHomePicSrc(){
      if(this.$route.name === 'home') {
        if (this.homePicIndex === this.homePicList.length - 1) {
          this.shuffleSort(this.homePicList)
          this.homePicIndex = 0
        }
        this.homePicIndex++
      }
    },
    shuffleSort(arr) {
      let n = arr.length;
      while(n--) {
        // 其中max + 1 = arr.length
        let index = Math.floor(Math.random() * n);
        [arr[index], arr[n]] = [arr[n], arr[index]];
      }
    }
  },
}
</script>


<style scoped>
#home-header {
  height: 100%;
  background-color: #ddd;
  /*background: url(https://s1.ax1x.com/2022/07/10/js9SKS.jpg) center center / cover no-repeat rgb(34, 34, 34);*/
}
#home-header img{
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-header {
  animation: head 1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 18.5rem;
  background:   #ddd;
}
.page-header img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.headTitle {
  /*布局*/
  position: absolute;
  margin: 50px 18rem 0 18rem;
  width: max-content;
  /*文本*/
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
  tab-size: 4;
  text-align: center;
  text-shadow: rgb(28, 31, 33) 0 3px 5px;
  /*外观*/
  word-break: break-all;
  color: rgb(255, 255, 255);
  border-image-repeat: stretch;
  border-image-source: none;
  border: 0 solid rgb(229, 231, 235);
}

.head-category-wrap {
  text-align: center;
  margin-top: 20px;
  font-size: 17px;
  line-height: 24px;
  tab-size: 4;
  text-shadow: rgb(28, 31, 33) 0 3px 5px;
  /*外观*/
  color: rgb(255, 255, 255);
  border-image-repeat: stretch;
  border-image-source: none;
  border-color: rgb(229, 231, 235);
  border-style: solid;
  border-width: 0;
}

.head-category {
  margin: 0 0.8rem 0 0;
  user-select: none;
}

.head-category > span {
  margin: 0 0.3em;
}

i {
  margin: 0 0.3rem 0 0;
}

.fa-pen-to-square {
  color: #70C1B3;
}

.fa-clock {
  color: #FFB4A2;
}

.fa-calendar-alt {
  color: #48a3c7;
}

.fa-eye {
  color: #9370db;
}
</style>