<template>

  <div>
    <!--背景颜色-->
    <div id="web_bg"></div>
    <!--导航栏-->
    <Nav/>
    <!--首图 + 页中标题 + 下滑箭头-->
    <Header/>

    <el-container class="layout_aside">
      <el-aside width="250px">
        <Introduction/>
        <Countdown></Countdown>
      </el-aside>

      <el-main class="main-container">
        <!--动态切换的页面，默认home页-->
        <keep-alive include="home">
          <router-view/>
        </keep-alive>
      </el-main>

      <el-aside width="250px">
        <tag-cloud v-if="$route.name !== 'blog'"/>
        <!---->
        <recommendation v-show="$route.name !== 'blog'"></recommendation>
        <Tocbot v-if="$route.name ==='blog'"></Tocbot>
      </el-aside>
    </el-container>

    <!--h5音乐播放器-->
    <MyAPlayer/>
    <Footer/>
  </div>
</template>

<script>
import Nav from "@/components/index/Nav";
import Header from "@/components/index/Header";
import MyAPlayer from "@/components/index/MyAPlayer";
import Introduction from "@/components/sidebar/Introduction";
import Tocbot from "@/components/sidebar/Tocbot";
import Countdown from "@/components/sidebar/Countdown"
import '../public/js/autoload.js'
import TagCloud from "@/components/sidebar/TagCloud";
import Recommendation from "@/components/sidebar/Recommendation";
import Footer from "@/components/index/Footer";

export default {
  name: 'Index',
  components: {
    Footer,
    Recommendation,
    TagCloud,
    Tocbot,
    Header,
    Nav,
    MyAPlayer,
    Introduction,
    Countdown
  },
  created() {
    //刷新后不记忆滚动位置
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual';
    }
    this.getAllSiteInfo()

  },
  watch: {
    //路由改变后不记忆滚动位置
    $route(to, from) {
      document.documentElement.scrollTop = 0
    }
  },
  methods: {
    getAllSiteInfo() {
      this.$request.get("/site-info/all").then(res => {
        this.$store.commit("setSiteInfo", res.data)
        this.$nextTick(()=>{
          document.title = this.$route.meta.title + res.data.baseInfo.webTitleSuffix
        })
      })
    },
  }
}
</script>

<style>
/*此处定义全局的css*/
/*引入插件v-viewer时，点击图片会给body添加.viewer-open样式，导致滚动条消失*/
/*已在node_modules里修改为如下内容*/
/*.viewer-open {*/
/*  padding-right: 0!important;*/
/*}*/

.dark-mode #home-header {
  filter: brightness(.6);
  /*方案二*/
  /*position: absolute;*/
  /*top: 0;*/
  /*right: 0;*/
  /*bottom: 0;*/
  /*left: 0;*/
  /*background-color: rgba(0, 0, 0, 0.5);*/
  /*content: '';*/
}
.dark-mode #footer{
  filter: brightness(.7);
}
.dark-mode .page-header {
  filter: brightness(.7);
}

.dark-mode .summary-item {
  background-color: rgba(51, 51, 51) !important;
  box-shadow: 0 4px 8px 6px rgb(7 17 27 / 20%);
}

.dark-mode .summary-title{
  color: #ddd !important;
}

.dark-mode .summary-category a{
  color: #ddd !important;
}

.dark-mode .content > p{
  color: #ddd;
}
.dark-mode #web_bg {
  background: #333;
}

.dark-mode .el-collapse-item__header {
  background: none;
  color: #ddd;
}
.dark-mode .divLine{
  border-top: 1px solid #ddd;
}
.dark-mode .el-card__body {
  border-radius: 8px;
  background-color: rgba(51, 51, 51);
}

.dark-mode .el-collapse-item__content {
  background: rgba(51, 51, 51);
  color: #ddd;
}

.dark-mode .el-card {
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, .2);
  background-color: #333;
  color: #ddd;
}
.dark-mode .el-card.is-hover-shadow:hover {
  box-shadow: 0 4px 12px 12px rgb(0, 0, 0, .3);
}
.dark-mode .friend{
  border: 2px solid rgb(12, 12, 12) !important;
}
.dark-mode .friend:hover{
  box-shadow: 0 34px 20px -24px rgb(12, 12, 12,0.6) !important;
}
.dark-mode .note-layout{
  filter: brightness(.8);
  color: #303133;
}
.dark-mode .note-layout .content > p{
  color: #00a8e8;
}

.dark-mode .rec-title{
  color: #ddd !important;
}

.dark-mode .rec-title:hover{
  color: #49b1f5 !important;
}
/*定制全局element ui样式*/
.el-collapse-item__wrap {
  border-bottom: none;
}

.el-collapse {
  border-bottom: none;
}

.el-collapse-item__header {
  background: none;
}

.el-tooltip__popper {
  font-size: 14px;
}

.el-tooltip__popper.is-dark {
  background: #555555;
  color: #FFF;
}

.el-tooltip__popper[x-placement^=top] .popper__arrow {
  border-top-color: #555555;

}

.el-tooltip__popper[x-placement^=top] .popper__arrow::after {
  border-top-color: #555555;
}

.el-card__body, .el-main {
  padding: 5px 20px 20px 20px;
}

.el-main {
  /*bottom为50px是为了给渐入特效的50px滚动留出空间，否则会产生滚动条，不美观*/
  padding: 0 20px 50px 20px;
  overflow: visible;
}
.el-main .el-card{
  /*!*解决评论区的最下方的评论展开时表情框被隐藏的问题*!*/
  overflow: visible;
}
.el-card {
  border-radius: 8px;
  border: none;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: rgba(59, 241, 213, 0.8)
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(59, 241, 213, 0.8);
  color: #fff;
  box-shadow: 0 2px 12px #3bf1d5cc;
}
/*背景渐变色*/
#web_bg {
  position: fixed;
  z-index: -999;
  width: 100%;
  height: 100%;
  background: url(https://iceburst.top/img/texture.svg), linear-gradient(60deg, #EAECC6, #00E4FF59);
  background-size: 420px auto, auto;
}
body {
  /*取消样式表默认的8px外边距*/
  margin: 0;
  /*MIUI 13字体*/
  font-family: MiSans, serif;
  font-weight: 500;
  transition: all .2s ease-in-out;
  min-width: 1590px;
}

/*初音鼠标样式*/
* {
  cursor: url(assets/img/Hatsune1.png), auto;
}
a, a > *,
svg > path,
button,
.onOff,
.onOff  > *,
.sunMoon > *,
.moment-toolbar-item, n.moment-toolbar-item > *{
  cursor: url(assets/img/Hatsune2.png), auto !important;
}

.main-container{
  width: 100vh;
}

/*博文中间部分动画*/
@keyframes article {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    transform: translateY(0);
  }
}

/*顶部head过渡动画*/
@keyframes head {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    transform: translateY(0);
  }
}

@keyframes dark-head {
  0% {
    opacity: 0;
    filter: brightness(.8);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    transform: translateY(0);
  }
}

/*父评论过渡动画*/
@keyframes comment-item-wrap {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    transform: translateY(0);
  }
}

/*子评论外框动画(会带着子评论一起移动)*/
@keyframes subItem {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    transform: translateY(0);
  }
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.layout_aside {
  margin: 0 auto;
  padding: 2rem 15px;
  max-width: 1440px;
}

/*滚动条设置*/
body::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}

body::-webkit-scrollbar-thumb { /* 滚动条上的滚动滑块. */
  background: linear-gradient(#00E4FFFF, #eaec64); /* 背景颜色 */
}

/*选择文字的背景色*/
::selection {
  background: #00C4B6CC;
  color: #f7f7f7;
}

@font-face {
  /*糖果屋大佬的cdn*/
  src: url("https://npm.elemecdn.com/akilar-candyassets/fonts/SAOUI.ttf");
  font-family: SAOUI;
}

</style>