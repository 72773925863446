<template>
  <div id="footer">
    <div id="footer-wrap" style="">
      <span class="my-face">ღゝ◡╹)ノ♡</span>
      <div class="copyright">
        <span v-if="siteInfo.baseInfo">{{ siteInfo.baseInfo.copyright.title }}</span>
        <span v-if="siteInfo.baseInfo">{{ siteInfo.baseInfo.copyright.siteName }}</span>
        <i class="fa-sharp fa-solid fa-fan animated faa-spin"></i>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          <span v-if="siteInfo.baseInfo">{{ siteInfo.baseInfo.beian }}</span>
        </a>
      </div>
      <div class="badge">
        <a v-for="item in siteInfo.badges" :key="item.id" :href="item.value.linkUrl" :title="item.value.title"
           target="_blank">
          <img :src="item.value.imgUrl">
        </a>
      </div>
    </div>
    <div id="jsi-flying-fish-container">
      <img src="https://i0.hdslb.com/bfs/album/4226a76446d8c2a8aeaa20e007973ad451765408.png" style="position: absolute;
  width: 100%;
  height: 150%;
  object-fit: cover;z-index: -1">
    </div>
  </div>
</template>

<script>
import {fish} from "@/assets/js/fish";
import {mapState} from "vuex";

export default {
  name: "Footer",
  computed: {
    ...mapState(['siteInfo'])
  },
  data(){
    return {

    }
  },
  //使用的js有获取元素行为时，需要挂载完元素后，即在mounted里使用。如果在此之前使用，元素还不存在
  //故需改造js 将其导出
  mounted() {
      fish()
  }
}
</script>

<style scoped>

#footer {
  position: relative;
  background-size: cover;
  overflow: hidden;
  z-index: 0;
  background: #9fefaf;
  /*background: #9fefaf url("https://s1.ax1x.com/2022/09/27/xZa6hV.png") no-repeat 50% 35%;*/
}

#footer-wrap {
  position: absolute;
  text-align: center;
  inset: 10px;
  color: #fff;
}

#footer-wrap a {
  color: #fff;
  margin-right: 16px;
}

#footer-wrap a:hover {
  color: #9fefaf;
}

#footer-wrap .footer-separator {
  margin: 0 0.2rem;
}

#footer-wrap .icp-icon {
  padding: 0 4px;
  vertical-align: text-bottom;
}

.badge {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

}
.copyright {
  margin: 10px;
}
.copyright span{
  margin: 0 10px;
}
.my-face {
  animation: my-face 5s infinite ease-in-out;
  display: inline-block;
  margin: 0 5px;
}

@keyframes my-face {
  2%, 24%, 80% {
    -webkit-transform: translate(0, 1.5px) rotate(1.5deg);
    -moz-transform: translate(0, 1.5px) rotate(1.5deg);
    -ms-transform: translate(0, 1.5px) rotate(1.5deg);
    -o-transform: translate(0, 1.5px) rotate(1.5deg);
    transform: translate(0, 1.5px) rotate(1.5deg)
  }

  4%, 68%, 98% {
    -webkit-transform: translate(0, -1.5px) rotate(-.5deg);
    -moz-transform: translate(0, -1.5px) rotate(-.5deg);
    -ms-transform: translate(0, -1.5px) rotate(-.5deg);
    -o-transform: translate(0, -1.5px) rotate(-.5deg);
    transform: translate(0, -1.5px) rotate(-.5deg)
  }

  38%, 6% {
    -webkit-transform: translate(0, 1.5px) rotate(-1.5deg);
    -moz-transform: translate(0, 1.5px) rotate(-1.5deg);
    -ms-transform: translate(0, 1.5px) rotate(-1.5deg);
    -o-transform: translate(0, 1.5px) rotate(-1.5deg);
    transform: translate(0, 1.5px) rotate(-1.5deg)
  }

  8%, 86% {
    -webkit-transform: translate(0, -1.5px) rotate(-1.5deg);
    -moz-transform: translate(0, -1.5px) rotate(-1.5deg);
    -ms-transform: translate(0, -1.5px) rotate(-1.5deg);
    -o-transform: translate(0, -1.5px) rotate(-1.5deg);
    transform: translate(0, -1.5px) rotate(-1.5deg)
  }

  10%, 72% {
    -webkit-transform: translate(0, 2.5px) rotate(1.5deg);
    -moz-transform: translate(0, 2.5px) rotate(1.5deg);
    -ms-transform: translate(0, 2.5px) rotate(1.5deg);
    -o-transform: translate(0, 2.5px) rotate(1.5deg);
    transform: translate(0, 2.5px) rotate(1.5deg)
  }

  12%, 64%, 78%, 96% {
    -webkit-transform: translate(0, -.5px) rotate(1.5deg);
    -moz-transform: translate(0, -.5px) rotate(1.5deg);
    -ms-transform: translate(0, -.5px) rotate(1.5deg);
    -o-transform: translate(0, -.5px) rotate(1.5deg);
    transform: translate(0, -.5px) rotate(1.5deg)
  }

  14%, 54% {
    -webkit-transform: translate(0, -1.5px) rotate(1.5deg);
    -moz-transform: translate(0, -1.5px) rotate(1.5deg);
    -ms-transform: translate(0, -1.5px) rotate(1.5deg);
    -o-transform: translate(0, -1.5px) rotate(1.5deg);
    transform: translate(0, -1.5px) rotate(1.5deg)
  }

  16% {
    -webkit-transform: translate(0, -.5px) rotate(-1.5deg);
    -moz-transform: translate(0, -.5px) rotate(-1.5deg);
    -ms-transform: translate(0, -.5px) rotate(-1.5deg);
    -o-transform: translate(0, -.5px) rotate(-1.5deg);
    transform: translate(0, -.5px) rotate(-1.5deg)
  }

  18%, 22% {
    -webkit-transform: translate(0, .5px) rotate(-1.5deg);
    -moz-transform: translate(0, .5px) rotate(-1.5deg);
    -ms-transform: translate(0, .5px) rotate(-1.5deg);
    -o-transform: translate(0, .5px) rotate(-1.5deg);
    transform: translate(0, .5px) rotate(-1.5deg)
  }

  20%, 36%, 46% {
    -webkit-transform: translate(0, -1.5px) rotate(2.5deg);
    -moz-transform: translate(0, -1.5px) rotate(2.5deg);
    -ms-transform: translate(0, -1.5px) rotate(2.5deg);
    -o-transform: translate(0, -1.5px) rotate(2.5deg);
    transform: translate(0, -1.5px) rotate(2.5deg)
  }

  26%, 50% {
    -webkit-transform: translate(0, .5px) rotate(.5deg);
    -moz-transform: translate(0, .5px) rotate(.5deg);
    -ms-transform: translate(0, .5px) rotate(.5deg);
    -o-transform: translate(0, .5px) rotate(.5deg);
    transform: translate(0, .5px) rotate(.5deg)
  }

  28% {
    -webkit-transform: translate(0, .5px) rotate(1.5deg);
    -moz-transform: translate(0, .5px) rotate(1.5deg);
    -ms-transform: translate(0, .5px) rotate(1.5deg);
    -o-transform: translate(0, .5px) rotate(1.5deg);
    transform: translate(0, .5px) rotate(1.5deg)
  }

  30%, 40%, 62%, 76%, 88% {
    -webkit-transform: translate(0, -.5px) rotate(2.5deg);
    -moz-transform: translate(0, -.5px) rotate(2.5deg);
    -ms-transform: translate(0, -.5px) rotate(2.5deg);
    -o-transform: translate(0, -.5px) rotate(2.5deg);
    transform: translate(0, -.5px) rotate(2.5deg)
  }

  32%, 34%, 66% {
    -webkit-transform: translate(0, 1.5px) rotate(-.5deg);
    -moz-transform: translate(0, 1.5px) rotate(-.5deg);
    -ms-transform: translate(0, 1.5px) rotate(-.5deg);
    -o-transform: translate(0, 1.5px) rotate(-.5deg);
    transform: translate(0, 1.5px) rotate(-.5deg)
  }

  42% {
    -webkit-transform: translate(0, 2.5px) rotate(-1.5deg);
    -moz-transform: translate(0, 2.5px) rotate(-1.5deg);
    -ms-transform: translate(0, 2.5px) rotate(-1.5deg);
    -o-transform: translate(0, 2.5px) rotate(-1.5deg);
    transform: translate(0, 2.5px) rotate(-1.5deg)
  }

  44%, 70% {
    -webkit-transform: translate(0, 1.5px) rotate(.5deg);
    -moz-transform: translate(0, 1.5px) rotate(.5deg);
    -ms-transform: translate(0, 1.5px) rotate(.5deg);
    -o-transform: translate(0, 1.5px) rotate(.5deg);
    transform: translate(0, 1.5px) rotate(.5deg)
  }

  48%, 74%, 82% {
    -webkit-transform: translate(0, -.5px) rotate(.5deg);
    -moz-transform: translate(0, -.5px) rotate(.5deg);
    -ms-transform: translate(0, -.5px) rotate(.5deg);
    -o-transform: translate(0, -.5px) rotate(.5deg);
    transform: translate(0, -.5px) rotate(.5deg)
  }

  52%, 56%, 60% {
    -webkit-transform: translate(0, 2.5px) rotate(2.5deg);
    -moz-transform: translate(0, 2.5px) rotate(2.5deg);
    -ms-transform: translate(0, 2.5px) rotate(2.5deg);
    -o-transform: translate(0, 2.5px) rotate(2.5deg);
    transform: translate(0, 2.5px) rotate(2.5deg)
  }

  58% {
    -webkit-transform: translate(0, .5px) rotate(2.5deg);
    -moz-transform: translate(0, .5px) rotate(2.5deg);
    -ms-transform: translate(0, .5px) rotate(2.5deg);
    -o-transform: translate(0, .5px) rotate(2.5deg);
    transform: translate(0, .5px) rotate(2.5deg)
  }

  84% {
    -webkit-transform: translate(0, 1.5px) rotate(2.5deg);
    -moz-transform: translate(0, 1.5px) rotate(2.5deg);
    -ms-transform: translate(0, 1.5px) rotate(2.5deg);
    -o-transform: translate(0, 1.5px) rotate(2.5deg);
    transform: translate(0, 1.5px) rotate(2.5deg)
  }

  90% {
    -webkit-transform: translate(0, 2.5px) rotate(-.5deg);
    -moz-transform: translate(0, 2.5px) rotate(-.5deg);
    -ms-transform: translate(0, 2.5px) rotate(-.5deg);
    -o-transform: translate(0, 2.5px) rotate(-.5deg);
    transform: translate(0, 2.5px) rotate(-.5deg)
  }

  92% {
    -webkit-transform: translate(0, .5px) rotate(-.5deg);
    -moz-transform: translate(0, .5px) rotate(-.5deg);
    -ms-transform: translate(0, .5px) rotate(-.5deg);
    -o-transform: translate(0, .5px) rotate(-.5deg);
    transform: translate(0, .5px) rotate(-.5deg)
  }

  94% {
    -webkit-transform: translate(0, 2.5px) rotate(.5deg);
    -moz-transform: translate(0, 2.5px) rotate(.5deg);
    -ms-transform: translate(0, 2.5px) rotate(.5deg);
    -o-transform: translate(0, 2.5px) rotate(.5deg);
    transform: translate(0, 2.5px) rotate(.5deg)
  }

  0%, 100% {
    -webkit-transform: translate(0, 0) rotate(0);
    -moz-transform: translate(0, 0) rotate(0);
    -ms-transform: translate(0, 0) rotate(0);
    -o-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0)
  }
}
</style>