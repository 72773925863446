<template>
  <el-card shadow="hover" style="width: 300px;margin-bottom: 20px;">
    <div class="tag-title">
      <i class="fas fa-tags"></i>
      标签云
    </div>
    <div class="tag-text">
      <router-link v-for="item in tagList" :key="item.tagId" :style="{'font-size': tagWeight[item.count] * 2 + 1.1  + 'em', color: item.color}"
                   :to="`/tag/${item.tagName}`"
                   class="tag-cloud">
        {{ item.tagName }}
      </router-link>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "TagCloud",
  data() {
    return {
      tagList: null,
      tagWeight: null,
    }
  },
  created() {
    this.getTagCloud()
  },
  methods: {
    getTagCloud() {
      this.$request.get("/tag/tag-cloud").then(res => {
        this.tagList = res.data.countInfo
        this.tagWeight = res.data.weight
      })
    }
  }
}
</script>

<style scoped>
/deep/ .el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
  box-shadow: 0 4px 12px 12px rgb(7 17 27 / 15%);
}

a {
  display: inline-block;
  padding: 0 4px;
  line-height: 2;
  text-decoration: none;
  word-wrap: break-word;
  transition: all 0.3s;
  overflow-wrap: break-word;
}
/*a:hover{*/
/*  color: #49b1f5 !important;;*/
/*}*/
.tag-text a:hover {
  transform: scale(1.2);
}

.tag-title {
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #ebeef5;
  font-size: 20px;
}

</style>