<template>
  <!--文章目录-->
  <el-card class="content-fixed" shadow="hover" style="border-radius: 8px;width: 300px">
    <div class="item-headline">
      <i class="fas fa-stream"></i>
      <span>目录</span>
    </div>
    <div class="js-toc my-toc" style="max-height: 400px"></div>
  </el-card>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Tocbot",
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    ...mapState(['renderOver', 'navVisible'])
  },
  watch: {
    renderOver() {
      if (this.renderOver === true) {

        this.tocbotAllInit()
      }
    },
    navVisible() {
      this.isActive = this.navVisible
    }
  },
  mounted() {
    //目录锚点随页面滚动（tocbot真辣鸡啊居然没有这个功能，差评）
    window.addEventListener('scroll', this.tocbotIndexScroll)
  },
  beforeDestroy(){
    //随着页面离开，移除事件监听
    window.removeEventListener('scroll', this.tocbotIndexScroll)
  },
  methods: {
    tocbotAllInit() {
      tocbot.init({
        // Where to render the table of contents.
        tocSelector: '.js-toc',
        // Where to grab the headings to build the table of contents.
        contentSelector: '.js-toc-content',
        // Which headings to grab inside of the contentSelector element.
        headingSelector: 'h1,h2,h3,h4',
        // Element to add the positionFixedClass to.
        positionFixedSelector: '.my-toc',
        // Smooth scrolling enabled.
        scrollSmooth: true,
        // Smooth scroll duration.
        scrollSmoothDuration: 420,
        // 到顶部导航条的距离
        scrollSmoothOffset: -60,
        //触发目录关联的距离（距离标题的距离）
        // scrollContainer: 'my-toc',
        headingsOffset: 53,
        disableTocScrollSync: true,
        orderedList: false,
      })
    },
    tocbotIndexScroll(){
      //offsetTop 元素（中部）距离父容器的距离   scrollTop 滚动条距离父容器顶部的距离  scrollHeight 元素高度
      var container = document.getElementsByClassName('js-toc')[0]
      var element = document.getElementsByClassName('is-active-link')[0]
      //由于设置了line-height = 32px，故减去行高影响以计算真正的移动距离（无行高则不需要减去）
      container.scrollTop = element.offsetTop - 32 - element.scrollHeight * 0.5
    }
  }
}
</script>

<style>
.el-card.is-always-shadow.content-fixed, .el-card.is-hover-shadow.content-fixed:focus, .el-card.is-hover-shadow.content-fixed:hover {
  box-shadow: 0 4px 12px 12px rgb(7 17 27 / 15%);
}
/*自定义目录css，不引入官方css*/
.my-toc {
  overflow-y: auto
}

/*.scrollbar-wrapper {*/
/*  max-height: 400px;*/
/*}*/
.my-toc .toc-list li > ul {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #cacaca;
}

.my-toc li {
  list-style: none;
  padding-right: 10px;
}

.my-toc .toc-list {
  list-style-type: none;
  margin: 0;
  padding-left: 10px
}

.my-toc .toc-list li a {
  display: block;
  font-size: 15px;
  font-weight: bold;
  line-height: 32px;
  padding: 0 0 0 0.3rem;
  border-left: 3px solid transparent;
  color: #666261;
}

.my-toc .toc-list li a:hover {
  color: #fbbd08;
}

.my-toc a.toc-link {
  color: currentColor;
  height: 100%
}

.is-collapsible {
  max-height: 1000px;
  overflow: hidden;
  transition: all 300ms ease-in-out
}

.my-toc .is-collapsed {
  max-height: 0
}

.item-headline {
  margin-bottom: 10px;
  font-size: 1.25em;
}

.item-headline > span {
  margin-left: 0.5rem;
}

.content-fixed {
  position: sticky;
  top: 20px;
}

.visible ~ .layout_aside > .el-aside > .content-fixed {
  /*通过nav透明显示联合定位到content-fixed，为其覆盖样式*/
  /*不能通过设置其他类的方式覆盖元素css属性，否则不会触发transition效果*/
  transition: top 0.5s;
  top: 78px;
}

.el-aside {
  /*踩坑：overflow: auto/hidden会使sticky失效*/
  overflow: initial;
}

.my-toc .is-active-link {

  color: rgb(213, 183, 64) !important;
}

/*滚动条设置*/
.my-toc::-webkit-scrollbar { /* 滚动条 */
  width: 10px;
}

.my-toc::-webkit-scrollbar-thumb { /* 滚动条上的滚动滑块. */
  background: linear-gradient(#00E4FFFF, #eaec64); /* 背景颜色 */
}
</style>