<template>
  <el-card class="countdown" shadow="hover">
    <div class="countdown-title">
      <i class="fa-solid fa-hourglass-half"></i>
      <!--<svg class="icon" height="18" viewBox="0 0 1024 1024" width="18" xmlns="http://www.w3.org/2000/svg">-->
      <!--  <path-->
      <!--      d="M864.801 895.471h-33.56v-96.859c0-126.081-73.017-235.093-179.062-287.102 106.046-52.01 179.062-161.022 179.062-287.102v-96.859h33.56c17.301 0 31.325-14.327 31.325-32 0-17.673-14.024-32-31.325-32H159.018c-17.3 0-31.325 14.327-31.325 32 0 17.673 14.025 32 31.325 32h33.02v96.859c0 126.08 73.016 235.092 179.061 287.102-106.046 52.009-179.062 161.02-179.062 287.101v96.859h-33.02c-17.3 0-31.325 14.326-31.325 32s14.025 32 31.325 32H864.8c17.301 0 31.325-14.326 31.325-32s-14.023-31.999-31.324-31.999zM256.05 222.427v-94.878h513.046v94.878c0 141.674-114.85 256.522-256.523 256.522-141.674 0-256.523-114.848-256.523-256.522zm513.046 673.044H256.05v-94.879c0-141.674 114.849-256.521 256.523-256.521 141.673 0 256.523 114.848 256.523 256.521v94.879z"></path>-->
      <!--  <path-->
      <!--      d="M544.141 384c0-17.69-14.341-32.031-32.031-32.031-71.694 0-127.854-56.161-127.854-127.855 0-17.69-14.341-32.032-32.031-32.032s-32.032 14.341-32.032 32.032c0 107.617 84.3 191.918 191.917 191.918 17.69 0 32.031-14.342 32.031-32.032z"></path>-->
      <!--</svg>-->
      人生倒计时
    </div>
    <div class="countdown-contain">
      <!--时计时-->
      <div class="item">
        <div class="title">
          今日已经过去
          <span class="text">{{ pastHours }}</span>
          小时
        </div>
        <!--滚动条-->
        <div class="progress">
          <div class="progress-bar">
            <div ref="hours" class="progress-bar-inner progress-bar-inner-0"></div>
          </div>
          <div class="progress-percentage">{{ hoursPer }}</div>
        </div>
      </div>
      <!--日计时-->
      <div class="item">
        <div class="title">
          这周已经过去
          <span class="text">{{ pastDaysInWeek }}</span>
          天
        </div>
        <div class="progress">
          <div class="progress-bar">
            <div ref="days" class="progress-bar-inner progress-bar-inner-1"></div>
          </div>
          <div class="progress-percentage">{{ daysInWeekPer }}</div>
        </div>
      </div>
      <!--周计时-->
      <div class="item">
        <div class="title">
          本月已经过去
          <span class="text">{{ pastDaysInMonth }}</span>
          天
        </div>
        <div class="progress">
          <div class="progress-bar">
            <div ref="weeks" class="progress-bar-inner progress-bar-inner-2"></div>
          </div>
          <div class="progress-percentage">{{ daysInMonthPer }}</div>
        </div>
      </div>
      <!--月计时-->
      <div class="item">
        <div class="title">
          今年已经过去
          <span class="text">{{ pastMonth }}</span>
          个月
        </div>
        <div class="progress">
          <div class="progress-bar">
            <div ref="months" class="progress-bar-inner progress-bar-inner-3"></div>
          </div>
          <div class="progress-percentage">{{ monthsInYearPer }}</div>
        </div>
      </div>
    </div>
  </el-card>

</template>

<script>
export default {
  name: "Countdown",
  data() {
    return {
      pastHours: 0,
      hoursPer: 0,
      pastDaysInWeek: 0,
      daysInWeekPer: 0,
      pastDaysInMonth: 0,
      daysInMonthPer: 0,
      pastMonth: 0,
      monthsInYearPer: 0,
    }
  },
  created() {
    this.timeLifeInit()
  },
  mounted() {
    //每半分钟重新统计计时
    setInterval(() => {
      this.timeLifeInit()
    }, 1000 * 30)
  },
  methods: {
    timeLifeInit() {
      let date = new Date()
      let year = date.getFullYear()
      let month = date.getMonth()
      //日计时
      this.pastHours = date.getHours()
      this.hoursPer = Math.floor(date.getHours() / 24 * 100) + "%"
      this.$nextTick(() => {
        this.$refs.hours.style.width = this.hoursPer.toString()
      })
      //周计时
      let nowDay = date.getDay()
      if (nowDay === 0) {
        nowDay = 7
      }
      this.pastDaysInWeek = nowDay
      this.daysInWeekPer = Math.floor(nowDay / 7 * 100) + "%"
      this.$nextTick(() => {
        this.$refs.days.style.width = this.daysInWeekPer.toString()
      })
      //月计时
      this.pastDaysInMonth = date.getDate()
      //获取本月的总天数
      let monthAllDays = new Date(year, month + 1, 0).getDate()
      this.daysInMonthPer = Math.floor(date.getDate() / monthAllDays * 100) + "%"
      this.$nextTick(() => {
        this.$refs.weeks.style.width = this.daysInMonthPer.toString()
      })
      //年计时
      this.pastMonth = month + 1
      this.monthsInYearPer = Math.floor(this.pastMonth / 12 * 100) + "%"
      this.$nextTick(() => {
        this.$refs.months.style.width = this.monthsInYearPer.toString()
      })
    }

  }
}
</script>

<style scoped>
/deep/ .el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
  box-shadow: 0 4px 12px 12px rgb(7 17 27 / 15%);
}

.countdown {
  /*position: relative;*/
  /*width: 300px;*/
  border-radius: 8px;
  box-shadow: 0px 0px 20px -5px rgba(158, 158, 158, 0.22);
  overflow: hidden;
}

.countdown .item {
  margin-bottom: 15px
}

.countdown .item:last-child {
  margin-bottom: 0
}

.countdown .item .rec-title {
  font-size: 15px;
  color: #909399;
  margin-bottom: 5px;
  display: flex;
  align-items: center
}

.countdown .item .rec-title .text {
  color: #409eff;
  font-weight: 500;
  font-size: 14px;
  margin: 0 5px
}

.countdown .item .progress {
  display: flex;
  align-items: center
}

.countdown .item .progress-bar {
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  background: #ebeef5;
  width: 0;
  min-width: 0;
  flex: 1;
  margin-right: 5px
}

.countdown .item .progress-bar-inner {
  width: 0;
  height: 100%;
  border-radius: 5px;
  transition: width 0.35s;
  -webkit-animation: progress 750ms linear infinite;
  animation: progress 750ms linear infinite
}

.countdown .item .progress-bar-inner-0 {
  background: #bde6ff linear-gradient(135deg, #50bfff 25%, transparent 25%, transparent 50%, #50bfff 50%, #50bfff 75%, transparent 75%, transparent 100%);
  background-size: 30px 30px
}

.countdown .item .progress-bar-inner-1 {
  background: #ffd980 linear-gradient(135deg, #f7ba2a 25%, transparent 25%, transparent 50%, #f7ba2a 50%, #f7ba2a 75%, transparent 75%, transparent 100%);
  background-size: 30px 30px
}

.countdown .item .progress-bar-inner-2 {
  background: #ffa9a9 linear-gradient(135deg, #ff4949 25%, transparent 25%, transparent 50%, #ff4949 50%, #ff4949 75%, transparent 75%, transparent 100%);
  background-size: 30px 30px
}

.countdown .item .progress-bar-inner-3 {
  background: #67c23a linear-gradient(135deg, #4f9e28 25%, transparent 25%, transparent 50%, #4f9e28 50%, #4f9e28 75%, transparent 75%, transparent 100%);
  background-size: 30px 30px
}

.countdown .item .progress-percentage {
  color: #909399;
}

.countdown-title {
  border-bottom: 1px solid #ebeef5;
  font-size: 20px;
  font-weight: 500;
  height: 45px;
  line-height: 45px;
}

.countdown-contain {
  position: relative;
  padding: 15px 0px;
}

.fa-hourglass-half {
  color: #b8946b;
}
</style>